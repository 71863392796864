import React, { useEffect, useState } from "react";
import {
  Close,
  DeleteForever,
  Edit,
  PlusOne,
  Search,
  Visibility,
} from "@styled-icons/material";
import { ClipLoader } from "react-spinners";
import {
  BtnEdit,
  BtnRemove,
  BtnSearch,
  BtnView,
  DivBtnEdit,
  DivBtnSearch,
  DivIdCategory,
  DivCategory,
  DivCategoryInfo,
  DivSearchCategory,
  DivTableSearch,
  DivInfo,
  TitleSearchCategory,
  NameInput,
  NameLabel,
  SpanCod,
  SpanName,
  DivOrgLoading,
  DivOrgInfo,
  DivOrgCard,
  BtnNewSubCategory,
  DivFilter,
  CodInput,
  DivOrgFilter,
  BtnCancel,
} from "./SearchCategoryStyle";
import UpdateCategory from "../../Update/UpdateCategory/UpdateCategory";
import InfoCategory from "../../Info/InfoCategory/InfoCategory";
import DeleteCategory from "../../DeleteComponent/DeleteCategory/DeleteCategory";
import NewSubCategory from "../../Forms/NewSubCategory/NewSubCategory";

export default function SearchCategory(props) {
  const categoryDetail = props.categorysInfo;

  // const [categoryInfo, setCategoryInfo] = useState([]);
  const [categoryPopUp, setCategoryPopUp] = useState(false);
  const [delCategoryOption, setDelCategoryOption] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryView, setSelectedCategoryView] = useState();
  const [filterCodCategory, setFilterCodCategory] = useState("");
  const [filterNameCategory, setFilterNameCategory] = useState("");
  const [filterInfoCategory, setFilterInfoCategory] = useState([]);

  const [selectNewSub, setSelectNewSub] = useState();
  const [dataNewSub, setDataNewSub] = useState();
  const [newSubPop, setNewSubPop] = useState(false);

  const [categoryView, setCategoryView] = useState(false);
  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState();
  const [loadingCategorys, setLoadingCategorys] = useState(false);
  const [dataCategoryUpdate, setDataCategoryUpdate] = useState([]);

  const createListCategorys = (dataCategory) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoCategory(dataCategory);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  const filterCategory = () => {
    const filterList = categoryDetail
      .filter((category) =>
        filterNameCategory.length > 0
          ? category.categoryName.includes(
              filterNameCategory.toLocaleLowerCase()
            )
          : category
      )
      .filter((category) =>
        filterCodCategory > 0
          ? category.idCategory === filterCodCategory
          : category
      );

    setFilterInfoCategory(filterList);
  };

  useEffect(() => {
    createListCategorys(categoryDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDetail]);

  useEffect(() => {
    setLoading(true);
    if (loadingCategorys) {
      props.getListCategorys();
    }
    setTimeout(() => {
      setLoadingCategorys(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCategorys]);

  return (
    <DivSearchCategory>
      <DivFilter>
        <TitleSearchCategory>Consultar Categorias</TitleSearchCategory>
        <DivOrgFilter show={props.disableFilter}>
          <NameLabel>Nome</NameLabel>
          <NameInput
            value={filterNameCategory}
            onChange={(e) => setFilterNameCategory(e.target.value)}
          />
          <NameLabel>Codigo</NameLabel>
          <CodInput
            value={filterCodCategory}
            onValueChange={(values, sourceInfo) => {
              setFilterCodCategory(parseInt(values.value));
            }}
          />
          <DivBtnSearch>
            <BtnSearch type="button" onClick={filterCategory}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCodCategory("");
                setFilterNameCategory("");
                setFilterInfoCategory(categoryDetail);
              }}
            >
              <Close />
            </BtnCancel>
          </DivBtnSearch>
        </DivOrgFilter>
      </DivFilter>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          filterInfoCategory.map((categoryInfo, index) => {
            return (
              <DivCategory key={index}>
                <DivOrgCard>
                  <DivInfo>
                    <DivIdCategory>{index + 1}</DivIdCategory>
                    <DivCategoryInfo>
                      <SpanName>
                        Categoria: {parseName(categoryInfo.categoryName)}
                      </SpanName>
                      <DivOrgInfo>
                        <SpanCod>Codigo: {categoryInfo.idCategory}</SpanCod>
                        {/* <SpanCod>Valor: {categoryInfo.valueCategory}</SpanCod> */}
                      </DivOrgInfo>
                    </DivCategoryInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setCategoryPopUp(!categoryPopUp);
                        setDataCategoryUpdate(categoryInfo);
                        setSelectedCategory(categoryInfo);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {categoryPopUp &&
                      categoryInfo.idCategory ===
                        selectedCategory.idCategory && (
                        <UpdateCategory
                          setLoadingCategorys={setLoadingCategorys}
                          dataCategoryUpdate={dataCategoryUpdate}
                          categoryPopUp={categoryPopUp}
                          setCategoryPopUp={setCategoryPopUp}
                        />
                      )}
                    <BtnView
                      onClick={() => {
                        setCategoryView(!categoryView);
                        setSelectedCategoryView(categoryInfo);
                        setDelCategoryOption(false);
                      }}
                    >
                      <Visibility />
                    </BtnView>
                    <BtnNewSubCategory
                      onClick={() => {
                        setNewSubPop(!newSubPop);
                        setSelectNewSub(categoryInfo);
                        setDataNewSub(categoryInfo);
                      }}
                    >
                      <PlusOne />
                    </BtnNewSubCategory>
                    {newSubPop &&
                      categoryInfo.idCategory === selectNewSub.idCategory && (
                        <NewSubCategory
                          setLoadingCategorys={setLoadingCategorys}
                          dataNewSub={dataNewSub}
                          newSubPop={newSubPop}
                          setNewSubPop={setNewSubPop}
                        />
                      )}

                    <BtnRemove
                      onClick={() => {
                        setDelCategoryOption(!delCategoryOption);
                        setSelectedCategory(categoryInfo);
                        setCategoryView(false);
                      }}
                    >
                      <DeleteForever />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivOrgCard>
                {categoryView &&
                  categoryInfo.idCategory ===
                    selectedCategoryView.idCategory && (
                    <InfoCategory
                      setLoadingCategorys={setLoadingCategorys}
                      selectedCategoryView={selectedCategoryView}
                      categoryView={categoryView}
                      setCategoryView={setCategoryView}
                    />
                  )}
                {delCategoryOption &&
                  categoryInfo.idCategory === selectedCategory.idCategory && (
                    <DeleteCategory
                      setLoadingCategorys={setLoadingCategorys}
                      selectedCategory={selectedCategory}
                      delCategoryOption={delCategoryOption}
                      setDelCategoryOption={setDelCategoryOption}
                    />
                  )}
              </DivCategory>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchCategory>
  );
}
